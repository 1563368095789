
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "housing-addition-options-modal-component",
  components: { Field },
  props: {
    publicId: String,
  },
  setup(props) {
    const { useReusableNavigation } = ResusableFunctions();

    const redirect = (route: string) => {
      hideModal(document.getElementById("housing_addition_option_modal"));

      setTimeout(() => {
        return useReusableNavigation(false, route, {
          publicId: props?.publicId,
        });
      }, 100);
    };

    return { redirect };
  },
});
