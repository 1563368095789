
import { defineComponent } from "vue";
import variables from "../../router/api";
import HousingAdditionOptions from "@/components/modals/HousingAdditionOptions.vue";
import { showModal } from "@/core/helpers/dom";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "policy-table-dropdown",
  components: { HousingAdditionOptions },
  props: {
    publicId: String,
    code: String,
    canBeRenewed: Boolean,
    hasPolicyDocument: Boolean,
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      removeLastHyphen,
    } = ResusableFunctions();

    const renewalRoute = (value, publicId) => {
      let route = "";

      if (
        ["HOM-HOU-OWN", "HOM-HOU", "HOM-HOU-OWN-", "HOM-HOU-"].includes(value)
      ) {
        route = `/policy/renewal/house-insurance/owned-building/${publicId}`;
      } else if (value === "HOM-HOU-TEN") {
        route = `/policy/renewal/house-insurance/rented-building/${publicId}`;
      } else if (value === "HOM-HOU-HPA") {
        route = `/policy/renewal/house-insurance/personal-accident/${publicId}`;
      } else if (value === "HOM-HOU-CON") {
        route = `/policy/renewal/house-insurance/contents/${publicId}`;
      } else if (value === "MOT") {
        route = `/policy/renewal/motor-insurance/${publicId}`;
      } else {
        route = "";
      }

      return route;
    };

    const additionRoute = (value, publicId) => {
      if (
        [
          "HOM-HOU-OWN",
          "HOM-HOU",
          "HOM-HOU-OWN-",
          "HOM-HOU-",
          "HOM-HOU-TEN",
        ].includes(value)
      ) {
        setTimeout(() => {
          showModal(document.getElementById("housing_addition_option_modal"));
        }, 100);
      } else if (value === "MOT") {
        return useReusableNavigation(
          true,
          `/policy/addition/motor-insurance/${publicId}`
        );
      } else {
        return variables.toastAlert("Invalid option passed.", "error");
      }
    };

    return {
      reinitialization,
      useReusableNavigation,
      removeLastHyphen,
      renewalRoute,
      additionRoute,
    };
  },
});
